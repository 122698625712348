// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/_actions/schemas/authSchema.ts"
);
import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import * as yup from 'yup';
import { validationRules } from '~/lib/validations';

export const authValidationSchema = yup.object({
  email: validationRules.email,
  password: validationRules.password,
});
export const applicationTokenSchema: any = yup.object({
  TokenName: validationRules.requiredString,
  EntityId: validationRules.requiredString,
});
